import { DEG2RAD } from "three/src/math/MathUtils";

export const cos = (d) => Math.cos(DEG2RAD * d);
export const sin = (d) => Math.sin(DEG2RAD * d);

export const usePolarPosition = () => {
  const polarPosition = (
    distance, // 視点からの距離
    theta, // 水平面から見上げる角度 (見下ろす場合は負) -90°〜90°
    phi // 正面から右に向く角度 (左方向は負) -180°〜180°
  ) => ({
    farness: distance * cos(theta) * cos(phi),
    height: distance * sin(theta),
    how_far_right: distance * cos(theta) * sin(phi),
    pitch: theta,
    yaw: -phi,
    roll: 0,
  });
  return polarPosition;
};
