import { memo } from "react";
import SimpleImageAdsense from "../../adsense/SimpleImageAdsense";
import { usePolarPosition } from "../../hooks/PolarPosition";
import GachaLegacy from "../20230625/Gacha";
import Gacha from "../20230715/Gacha";

import a from "./images/a.jpeg";
import b from "./images/b.jpeg";
import c from "./images/c.png";
import d from "./images/d.png";
import e from "./images/e.png";
import f from "./images/f.jpeg";
import g from "./images/g.jpeg";
import h from "./images/h.jpeg";
import i from "./images/i.jpeg";
import j from "./images/j.jpeg";

import e01 from "../20230715/images/e_heroine_prizes/e01.png";
import e02 from "../20230715/images/e_heroine_prizes/e02.png";
import e03 from "../20230715/images/e_heroine_prizes/e03.png";
import e04 from "../20230715/images/e_heroine_prizes/e04.png";

const ePrizes = [e01, e02, e03, e04];

const Objects = (props) => {
  const polarPosition = usePolarPosition();
  return (
    <>
      {/* A right > top left */}
      <SimpleImageAdsense {...polarPosition(45, 7, 90 - 7)} show image={a} w={1280} h={720} />

      {/* B right > top right */}
      <SimpleImageAdsense {...polarPosition(60, 7, 90 + 7)} show image={b} w={1280} h={720} />

      {/* C center */}
      <SimpleImageAdsense {...polarPosition(40, 2, 0)} show image={c} w={1280} h={720} />

      {/* D (Machine Gacha) left > top right */}
      <GachaLegacy position={{ box: polarPosition(50, 7, -90 + 7), prize: polarPosition(50, 7, -90 + 7) }} box={d} />

      {/* E (Heroine Gacha) left > bottom right */}
      <Gacha position={{ box: polarPosition(50, -7, -90 + 7), prize: polarPosition(65, -7, -90 + 7) }} box={e} prizes={ePrizes} />

      {/* F center > bottom */}
      <SimpleImageAdsense {...polarPosition(50, -15, 0)} show image={f} w={1280} h={720} />

      {/* G right > bottom left */}
      <SimpleImageAdsense {...polarPosition(60, -7, 90 - 7)} show image={g} w={1280} h={720} />

      {/* H right > bottom right */}
      <SimpleImageAdsense {...polarPosition(60, -7, 90 + 7)} show image={h} w={1280} h={720} />

      {/* I bottom > top left */}
      <SimpleImageAdsense {...polarPosition(60, -90 + 30, -12)} roll={-9} show image={i} w={1280} h={720} />

      {/* J bottom > top right */}
      <SimpleImageAdsense {...polarPosition(60, -90 + 30, 12)} roll={9} show image={j} w={1280} h={720} />
    </>
  );
};

export default memo(Objects);
