import { RAD2DEG } from "three/src/math/MathUtils";
import { sin, usePolarPosition } from "./PolarPosition";

const randomize = (min, max) => Math.random() * (max - min) + min;

export const useRandomPosition = () => {
  const polarPosition = usePolarPosition();
  const randomPosition = (
    distance = 200, // 視点からの距離
    minPhi = -180, // 正面から左方向に真後ろまで
    maxPhi = 180, // 正面から右方向に真後ろまで
    minTheta = -60, // 下に最大60度見下ろす
    maxTheta = 60 // 上に最大60度見上げる
  ) => {
    const theta = RAD2DEG * Math.asin(randomize(sin(minTheta), sin(maxTheta)));
    const phi = randomize(minPhi, maxPhi);
    return polarPosition(distance, theta, phi);
  };
  return randomPosition;
};
