import { memo, useState } from "react";
import SimpleHtml from "../adsense/SimpleHtml";
import SimpleImageAdsense from "../adsense/SimpleImageAdsense";
import { usePolarPosition } from "../hooks/PolarPosition";

import first from "./images/first.png";
import part1 from "./images/zenhan.png";
import part2Normal from "./images/kouhan.png";
import part2Rare from "./images/kouhan_g.png";
import last from "./images/last.png";

const Objects = (props) => {
  const polarPosition = usePolarPosition();
  const [gacha1Image, setGacha1Image] = useState(first);
  const [gacha2Image, setGacha2Image] = useState(first);
  const [lag, setLag] = useState(500);
  const unique = (src) => `${src}?${Date.now()}`;
  const animation = (src, length, setImage) => () =>
    new Promise((resolve) => {
      setImage(src);
      setTimeout(resolve, length);
    });
  const onClickGacha1 = () => {
    if (gacha1Image === first) {
      animation(part1, 1583, setGacha1Image)()
        .then(animation(part2Normal, 2458, setGacha1Image))
        .then(animation(last, 2000, setGacha1Image))
        .then(() => setGacha1Image(first));
    }
  };
  const onClickGacha2 = () => {
    if (gacha2Image === first) {
      animation(unique(part1), 1583 + lag, setGacha2Image)()
        .then(animation(unique(part2Rare), 2458 + lag, setGacha2Image))
        .then(animation(last, 2000, setGacha2Image))
        .then(() => setGacha2Image(first));
    }
  };
  return (
    <>
      <SimpleImageAdsense {...polarPosition(120, 30, 0)} alwaysLookAtCamera={false} show image={part1} w={1280} h={720} />
      <SimpleImageAdsense {...polarPosition(120, 30, -10)} alwaysLookAtCamera={false} show image={part2Normal} w={1280} h={720} />
      <SimpleImageAdsense {...polarPosition(120, 30, 10)} alwaysLookAtCamera={false} show image={part2Rare} w={1280} h={720} />

      <SimpleImageAdsense {...polarPosition(50, 0, -5)} alwaysLookAtCamera={false} show image={gacha1Image} w={1280} h={720} onClick={onClickGacha1} />
      <SimpleImageAdsense {...polarPosition(50, 0, 5)} alwaysLookAtCamera={false} show image={gacha2Image} w={1280} h={720} onClick={onClickGacha2} />

      <SimpleHtml {...polarPosition(50, 5, 0)} alwaysLookAtCamera={false} show>
        <div style={{ backgroundColor: "#ccc" }}>
          <p>↑↑↑上に3種類のAPNG↑↑↑</p>
        </div>
      </SimpleHtml>
      <SimpleHtml {...polarPosition(50, -5, -5)} alwaysLookAtCamera={false} show>
        <div style={{ backgroundColor: "#ccc" }}>
          <p>ガチャ1</p>
          <p>ノーマル / キャッシュ有効</p>
          <p>最初から再生されない場合あり</p>
        </div>
      </SimpleHtml>
      <SimpleHtml {...polarPosition(50, -6, 5)} alwaysLookAtCamera={false} show>
        <div style={{ backgroundColor: "#ccc" }}>
          <p>ガチャ2</p>
          <p>ゴールド / キャッシュ無効</p>
          <p>最初から再生されるが、ラグがあり</p>
          <p>アニメーションの後ろが切れる</p>
          <p>アニメーション延長(ms):</p>
          <input type="number" value={lag} onChange={(e) => setLag(e.target.valueAsNumber)} />
        </div>
      </SimpleHtml>
    </>
  );
};

export default memo(Objects);
