import { memo } from "react";
import SimpleImageAdsense from "../adsense/SimpleImageAdsense";
import { usePolarPosition } from "../hooks/PolarPosition";
import { usePosition as useLocation } from "../hooks/Position";

import a from "./images/a.jpg";
import b from "./images/b.jpg";
import c from "./images/c.jpg";
import d from "./images/d.jpg";
import e from "./images/e.jpg";
import f from "./images/f.jpg";
import g from "./images/g.jpg";
import h from "./images/h.jpg";
import i from "./images/i.png";

const location1 = { latitude: 35.65155165290284, longitude: 139.7548714294478 };

const Objects = (props) => {
  const polarPosition = usePolarPosition();
  const [_position, _getCurrent, _getCurrentSilent, get_in] = useLocation();
  return (
    <>
      {/* A: size L, lower top (location 1) */}
      <SimpleImageAdsense {...polarPosition(50, 30, 0)} show={get_in(location1, 750)} image={a} w={1280} h={720} />

      {/* B: size M, right > lower right (location 1) */}
      <SimpleImageAdsense {...polarPosition(75, -5, 90 + 5)} show={get_in(location1, 750)} image={b} w={1280} h={720} />

      {/* C: size M, left top */}
      <SimpleImageAdsense {...polarPosition(55, 10, -90)} show image={c} w={1280} h={720} />

      {/* D: size M, left center */}
      <SimpleImageAdsense {...polarPosition(55, 0, -90)} show image={d} w={1280} h={720} />

      {/* E: size M, right > upper right */}
      <SimpleImageAdsense {...polarPosition(100, 5, 90 + 5)} show image={e} w={1280} h={720} />

      {/* F: size M, right > upper left */}
      <SimpleImageAdsense {...polarPosition(100, 5, 90 - 5)} show image={f} w={1280} h={720} />

      {/* G: size M, right > lower left */}
      <SimpleImageAdsense {...polarPosition(75, -5, 90 - 5)} show image={g} w={1280} h={720} />

      {/* H: size M, left bottom */}
      <SimpleImageAdsense {...polarPosition(55, -10, -90)} show image={h} w={1280} h={720} />

      {/* I: size L, center */}
      <SimpleImageAdsense {...polarPosition(45, 0, 0)} show image={i} w={1280} h={720} />
    </>
  );
};

export default memo(Objects);
