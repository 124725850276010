import { memo, useState } from "react";
import SimpleImageAdsense from "../../../adsense/SimpleImageAdsense";

const TreasureBox = (props) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  return (
    <>
      {open ? (
        <SimpleImageAdsense {...props.position.content} show image={props.content} w={1280} h={720} />
      ) : (
        <SimpleImageAdsense {...props.position.cover} show image={props.cover} w={1280} h={720} onClick={handleClick} />
      )}
    </>
  );
};

export default memo(TreasureBox);
