import { memo } from "react";

const Footer = (props) => {
  return (
    <div className="absolute bottom z-[3000000] inset-x-0 bottom-0 m-auto flex justify-bottom pointer-events-none">
      <img src={props.src} alt="" className="w-full" />
    </div>
  );
};

export default memo(Footer);
