import { memo } from "react";

const Header = (props) => {
  return (
    <div className="absolute top z-[3000000] inset-x-0 m-auto flex justify-top pointer-events-none">
      <img src={props.src} alt="" className="w-full" />
    </div>
  );
};

export default memo(Header);
