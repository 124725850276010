import { memo } from "react";
import { usePolarPosition } from "../../../hooks/PolarPosition";
import MachineGacha from "../../20230625/Gacha";

const Objects = (props) => {
  const polarPosition = usePolarPosition();
  return (
    <>
      {/* D (Machine Gacha) center */}
      <MachineGacha position={{ box: polarPosition(55, 0, 0), prize: polarPosition(60, 0, 0) }} />
    </>
  );
};

export default memo(Objects);
