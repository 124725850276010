import { memo } from "react";

import footer from "./images/g_footer.png";
import header from "./images/h_header_and_below.gif";

const Decorator = (props) => {
  return (
    <>
      {/* H header */}
      <div className="absolute top z-[3000000] inset-x-0 m-auto flex justify-top pointer-events-none">
        <img src={header} alt="" className="w-full" />
      </div>

      {/* H below header */}
      <div className="absolute top-1/3 z-[3000000] inset-x-0 m-auto flex justify-center pointer-events-none">
        <img src={header} alt="" className="w-full" />
      </div>

      {/* G footer */}
      <div className="absolute bottom z-[3000000] inset-x-0 bottom-0 m-auto flex justify-bottom pointer-events-none">
        <img src={footer} alt="" className="w-full" />
      </div>
    </>
  );
};

export default memo(Decorator);
