import {React,  memo }  from 'react'
import Webcam from "react-webcam";
import GraffitiCanvas from './GraffitiCanvas';
import NotificationForUser from './NotificationForUser';
import {EventEmitter} from 'fbemitter'

function MRRootView(props) {
    const emitter = new EventEmitter();
    return (
        <div className="z-1">
            <div className="w-[100vw] h-[100vh] z-1">
                {props.decorator}
                <Webcam
                    className="w-full h-full absolute object-cover min-h-[100vh] min-w-[100vw]"
                    audio={false}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{
                        facingMode: { exact: "environment" }
                    }}
                    muted={true}
                />
                <NotificationForUser />
                <GraffitiCanvas emitter={emitter} graffiti={props.graffiti} />

            </div>
        </div>
  );
}
export default memo(MRRootView);
export const mainViewURL = "/";
