import { espoGachaLimitedPrizeUrl } from ".";
import { useLottery } from "../../../hooks/Lottery";
import b1 from "./images/b1.png";
import b2 from "./images/b2.png";
import b3 from "./images/b3.png";
import b4 from "./images/b4.png";
import b5 from "./images/b5.png";
export const EspoGachaLimitedPrizeInitializer = () => {
    const loto_specification = [
        { id: 0, ratio: 2, image: b1, stock: 2 },
        { id: 1, ratio: 3, image: b2, stock: 2 },
        { id: 2, ratio: 8, image: b3, stock: 8 },
        { id: 3, ratio: 0, image: b4, stock: 0 },
        { id: 4, ratio: 50, image: b5, stock: Infinity },
      ];
      const event_name = "espo_limited_prize_1202";
      const lottery = useLottery(event_name, loto_specification)
      lottery.init(event_name, loto_specification);  
      window.location.href=espoGachaLimitedPrizeUrl;
    return (<></>)
}