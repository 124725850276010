import { memo, useState } from "react";
import SimpleImageAdsense from "../../../adsense/SimpleImageAdsense";
import { usePolarPosition } from "../../../hooks/PolarPosition";

import { useLottery } from "../../../hooks/Lottery";
import a from "./images/a.png";
import b1 from "./images/b1.png";
import b2 from "./images/b2.png";
import b3 from "./images/b3.png";
import b4 from "./images/b4.png";
import b5 from "./images/b5.png";


const loto_specification = [
  { id: 0, ratio: 2, image: b1, stock: 2 },
  { id: 1, ratio: 3, image: b2, stock: 2 },
  { id: 2, ratio: 8, image: b3, stock: 8 },
  { id: 3, ratio: 0, image: b4, stock: 0 },
  { id: 4, ratio: 87, image: b5, stock: Infinity },
];

const Objects = (props) => {
  const event_name = "espo_limited_prize_1202";
  const lottery = useLottery(event_name, loto_specification)
  lottery.saveIfNotExists(event_name, loto_specification);
  const polarPosition = usePolarPosition();
  const [prize, setPrize] = useState(null);
  const handleClick = () => {
    if (prize !== null) return;
    setPrize(lottery.play().image)
  };
  return (
    <>
      {/* A: size L, center */}
      <SimpleImageAdsense {...polarPosition(prize === null ? 45 : 47, 0, 0)} show image={prize ?? a} w={1280} h={720} onClick={handleClick} />
    </>
  );
};

export default memo(Objects);
