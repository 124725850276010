import { memo } from "react";
import SimpleImageAdsense from "../../adsense/SimpleImageAdsense";
import { usePolarPosition } from "../../hooks/PolarPosition";

import a from "./images/a.jpeg";
import b from "./images/b.jpeg";
import c from "./images/c.jpeg";

const dTheta = 5;
const dPhi = 8;

const Objects = (props) => {
  const polarPosition = usePolarPosition();
  return (
    <>
      {/* A center > top */}
      <SimpleImageAdsense {...polarPosition(45, dTheta, 0)} show image={a} w={1280} h={720} />

      {/* B center > bottom left */}
      <SimpleImageAdsense {...polarPosition(45, -dTheta, -dPhi)} show image={b} w={1280} h={720} />

      {/* C center > bottom right */}
      <SimpleImageAdsense {...polarPosition(45, -dTheta, dPhi)} show image={c} w={1280} h={720} />
    </>
  );
};

export default memo(Objects);
