// example
// const array = [
//   { ratio: 10, image: image1 },
//   { ratio: 30, image: image2 },
//   { ratio: 60, image: image3 },
// ];
export const usePickProportionalRatio = () => (array) => {
  const ratioSum = array.reduce((sum, element) => sum + element.ratio, 0);
  const randomValue = ratioSum * Math.random();
  let ratioSubtotal = 0;
  for (const element of array) {
    ratioSubtotal += element.ratio;
    if (randomValue <= ratioSubtotal) return element.image;
  }
};
