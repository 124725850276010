import { memo } from "react";
import Footer from "../../../window-decoration/Footer";
import Header from "../../../window-decoration/Header";

import footer from "./images/g.jpg";
import header from "./images/h.jpg";

const Decorator = (props) => {
  return (
    <>
      {/* H header */}
      <Header src={header} />

      {/* G footer */}
      <Footer src={footer} />
    </>
  );
};

export default memo(Decorator);
