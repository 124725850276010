export const useLottery = (event_name, loto_specification) => {
    const key_with_prefix = (k) => "peta_loto_" + k;
    const nullstock_to_infinity = (stocks) => stocks.map(e => {if (e.stock === null) e.stock = Infinity; return e})
    const save = (key, saves) => window.localStorage.setItem(key_with_prefix(key), JSON.stringify(saves)) ;
    const update = save
    const load = (key) =>  nullstock_to_infinity(JSON.parse(window.localStorage.getItem(key_with_prefix(key))));
    const reset = (key) => window.localStorage.removeItem(key_with_prefix(key));
    const pick = (array) => {
        const ratioSum = array.reduce((sum, element) => sum + element.ratio, 0);
        const randomValue = ratioSum * Math.random();
        let ratioSubtotal = 0;
        for (const element of array) {
        ratioSubtotal += element.ratio;
        if (randomValue <= ratioSubtotal) return element;
        }
    }

    const empty = (loto_spec) => loto_spec.filter(e => e.stock).reduce((a, c) => a + c) >= 0
    const init = save;
    const saveIfNotExists = () => {
        const stock = load(event_name);
        if (!stock) {
            save(event_name, loto_specification);
        }
    }
    const play = () => {
        let stock = load(event_name);
        if (empty(stock)) return;
        let choose;
        do {
            choose = pick(stock);
        } while (choose.stock <= 0);
        choose.stock--
        stock.map(e => e.id === choose.id ? choose : e);
        update(event_name, stock);
        return choose;
    }
    const stock_to_default = () => save(event_name, loto_specification)
    const stock_data_delete = reset;
    return {
        init,
        saveIfNotExists,
        play, 
        stock_to_default,
        stock_data_delete,
    }
};