import {React, memo, useEffect, useState, useRef, forwardRef }  from 'react'
import {Canvas } from '@react-three/fiber'
import {PerspectiveCamera, TrackballControls, DeviceOrientationControls } from '@react-three/drei';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast'
import './GraffitiCanvas.css';
import { ARCanvas, ARMarker } from "@artcom/react-three-arjs"
import SimpleImageAdsense from './adsense/SimpleImageAdsense'
import YoutubeAdsense from './adsense/YoutubeAdsense'

import { usePosition } from './hooks/Position';
import HUD from './hud/HUD' 
import PostContent from './post/PostContent';

function GraffitiCanvas(props) {
    const from = (day_str) => new Date() >= new Date(Date.parse(day_str))
    const to = (day_str) => new Date(Date.parse(day_str)) >= new Date()
    const between = (from_str, to_str) => new Date(Date.parse(from_str)) <= new Date() && new Date() <= new Date(Date.parse(to_str))
    
    const emitter = props.emitter;
    // eslint-disable-next-line no-unused-vars
    const [posts, setPosts] = useState([])
    useEffect(() => {
        // fetch(process.env.REACT_APP_ENDPOINT + '/user/timeline', {method: 'GET'})
        // .then(res => res.json())
        // .then(data => {
        //     setPosts(data)
        // })
    },[])
    const chinchiro = {latitude : 43.02657921155656, longitude: 141.43408152709313}
    const office = {latitude : 35.65143430873481, longitude : 139.75481982133908}
    const otaru = { latitude: 43.19787140841189, longitude: 141.00327522360044 }

    // eslint-disable-next-line no-unused-vars
    const [position, getCurrent, getCurrentSilent, get_in] = usePosition();
    ;
    return (　
      <>
      <Canvas
      className='w-full h-full m-0 p-0 '
        gl={{
          antialias: false,
          powerPreference: "default",
          physicallyCorrectLights: true,
        }}
        sourceType="webcam"
        camera={{ fov: 23, near: 0.01, far: 10000, position: [0, 0, 0] }}
        onCreated={({ gl, camera }) => {
          gl.setSize(window.innerWidth, window.innerHeight);
        }}
      >
      <group>
        {/* {posts.map(e => <PostContent detail={e} />)} */}
      </group>

      {props.graffiti}

       <DeviceOrientationControls />
        <ARMarker type={"pattern"} patternUrl={"data/hiro.patt"}>
          <mesh>
            <boxBufferGeometry args={[1, 1, 1]} />
            <meshStandardMaterial color={"green"} />
          </mesh>
        </ARMarker>
      </Canvas>
      {/* <HUD positioning={position.active} display_object_count={5} movie_object_count={5} post_object_count={posts.length} onPositionUpdate={() => getCurrent()}/> */}
      </>
    );
}

export default memo(GraffitiCanvas);
