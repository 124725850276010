import { memo } from "react";
import SimpleImageAdsense from "../../../adsense/SimpleImageAdsense";
import { usePolarPosition } from "../../../hooks/PolarPosition";
import { useRandomPosition } from "../../../hooks/RandomPosition";
import { useRandomAngle } from "./hooks/RandomAngle";
import TreasureBox from "./TreasureBox";

import a from "./images/a.png";
import b from "./images/b.gif";
import c1 from "./images/c1.png";
import c2 from "./images/c2.png";
import c3 from "./images/c3.png";
import d from "./images/d.png";
import e from "./images/e.png";
import f01 from "./images/f/f01.jpeg";
import f02 from "./images/f/f02.jpeg";
import f03 from "./images/f/f03.jpeg";
import f04 from "./images/f/f04.jpeg";
import f05 from "./images/f/f05.jpeg";
import f06 from "./images/f/f06.jpeg";
import f07 from "./images/f/f07.jpeg";
import f08 from "./images/f/f08.jpeg";
import f09 from "./images/f/f09.jpeg";
import f10 from "./images/f/f10.jpeg";
import f11 from "./images/f/f11.jpeg";
import f12 from "./images/f/f12.jpeg";
import f13 from "./images/f/f13.jpeg";
import f14 from "./images/f/f14.jpeg";
import f15 from "./images/f/f15.jpeg";
import f16 from "./images/f/f16.jpeg";
import f17 from "./images/f/f17.jpeg";
import f18 from "./images/f/f18.jpeg";
import f19 from "./images/f/f19.jpeg";
import f20 from "./images/f/f20.jpeg";
import f21 from "./images/f/f21.jpeg";
import f22 from "./images/f/f22.jpeg";
import f23 from "./images/f/f23.jpeg";
import f24 from "./images/f/f24.jpeg";
import f25 from "./images/f/f25.jpeg";
import f26 from "./images/f/f26.jpeg";
import f27 from "./images/f/f27.jpeg";
import f28 from "./images/f/f28.jpeg";
import f29 from "./images/f/f29.jpeg";
import f30 from "./images/f/f30.jpeg";
import g from "./images/g.png";

const f = [f01, f02, f03, f04, f05, f06, f07, f08, f09, f10, f11, f12, f13, f14, f15, f16, f17, f18, f19, f20, f21, f22, f23, f24, f25, f26, f27, f28, f29, f30];

const Objects = (props) => {
  const polarPosition = usePolarPosition();
  const randomPosition = useRandomPosition();
  const { randomTheta, randomPhi } = useRandomAngle();
  const [b1Theta, b2Theta, b3Theta] = [...Array(3)].map(() => randomTheta());
  const b1Phi = randomPhi(-40, 40);
  const b2Phi = randomPhi(90 - 40, 90 + 40);
  const b3Phi = randomPhi(-90 - 40, -90 + 40);
  return (
    <>
      {/* A: size L, center */}
      <SimpleImageAdsense {...polarPosition(45, 0, 0)} show image={a} w={1280} h={720} />

      {/* B1: size L, random center */}
      <TreasureBox position={{ cover: polarPosition(65, b1Theta, b1Phi), content: polarPosition(45, b1Theta, b1Phi) }} cover={b} content={c1} />

      {/* B2: size L, random right */}
      <TreasureBox position={{ cover: polarPosition(65, b2Theta, b2Phi), content: polarPosition(45, b2Theta, b2Phi) }} cover={b} content={c2} />

      {/* B3: size L, random left */}
      <TreasureBox position={{ cover: polarPosition(65, b3Theta, b3Phi), content: polarPosition(45, b3Theta, b3Phi) }} cover={b} content={c3} />

      {/* D: size L, right */}
      <SimpleImageAdsense {...polarPosition(40, 0, 90)} show image={d} w={1280} h={720} />

      {/* E: size L, left */}
      <SimpleImageAdsense {...polarPosition(40, 0, -90)} show image={e} w={1280} h={720} />

      {/* F: size S, random everywhere */}
      {f.map((fImage, i) => (
        <SimpleImageAdsense key={i} {...randomPosition(100)} show image={fImage} w={1280} h={720} />
      ))}

      {/* G: size L, top */}
      <SimpleImageAdsense {...polarPosition(30, 25, 0)} show image={g} w={1280} h={720} />
    </>
  );
};

export default memo(Objects);
