import { memo } from "react";
import SimpleImageAdsense from "../adsense/SimpleImageAdsense";
import { usePolarPosition } from "../hooks/PolarPosition";

import a from "./images/img.jpg";


const Objects = (props) => {
  const polarPosition = usePolarPosition();
  return (
    <>
      {/* A: size L, center */}
      <SimpleImageAdsense {...polarPosition(70, 0, 0)} show image={a} w={1280} h={720}  />
    </>
  );
};

export default memo(Objects);
